import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useAccount, useDisconnect } from 'wagmi';

import {
  User as FireUser,
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut as fireSignOut,
  isSignInWithEmailLink,
  OAuthProvider,
} from 'firebase/auth';

import client from 'src/apollo/client';
import {
  GetUserByIdDocument,
  GetUserByIdQuery,
  GetUserIdByFirebaseQuery,
  GetUserIdByFirebaseDocument,
  CreateUserMutation,
  CreateUserDocument,
} from '@emint/graphql';
import { auth } from 'src/config/firebase';
import useMyWallets from 'src/hooks/useMyWallets';
import { Wallet } from 'src/types/users';
import { useHasMounted } from 'src/hooks/useHasMounted';

export interface User {
  id: number;
  name: string;
  lastName?: string;
  email: string;
  address: string;
  roles: ('user' | 'creator' | 'admin')[];
  completedTour: boolean | null;
  acceptedTermsOfUseAt?: string;
  notifications?: {
    acceptedInfoDev?: boolean;
    acceptedInfoDistribution?: boolean;
  };
}

export type CreatorConcession = {
  id: number;
  requesterId: number;
  reviewerId?: number;
  concessionType?: string;
  concessionStatus?: string;
  reviewedAt?: string;
  metadata?: {
    creatorIntentType?: 'CONTENT' | 'RAISE' | 'EVENT';
    creatorIntentStartedAt?: string;
  };
};

export interface AuthValues {
  user: User;
  fireUser: FireUser;
  isCreator: boolean;
  loginWithEmail: (email: string, isConfirm: boolean) => Promise<boolean>;
  loginWithGoogle: () => Promise<void>;
  loginWithFacebook: () => Promise<void>;
  loginWithApple: () => Promise<void>;
  signOutWithEthereum: () => void;
  refreshUser: () => void;
  loggedIn: boolean;
  walletAddress: `0x${string}`;
  wallets: Wallet[];
  createWallet: (address: string, provider: string) => void;
  setLoggingIn: Dispatch<SetStateAction<boolean>>;
  ip: string;
  fetchConcessions: () => Promise<CreatorConcession>;
}

export const AuthContext = createContext<AuthValues>(null);

export function AuthProvider({ children }) {
  const router = useRouter();
  const hasMounted = useHasMounted();
  const [loggedIn, setLoggedIn] = useState(getPersistentLoggedInState());
  const [walletAddress, setWalletAddress] = useState<`0x${string}`>(`0x${0}`);
  const [fireUser, setFireUser] = useState<FireUser>(null);
  const [user, setUser] = useState(null);
  const { wallets, createWallet } = useMyWallets(user);
  const [ip, setIp] = useState(null);
  const isCreator = user?.roles.includes('creator');
  const { disconnect } = useDisconnect();
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [justCreatedUser, setJustCreatedUser] = useState(false);
  const [userError, setUserError] = useState(null);
  const { address } = useAccount();

  useAccount({
    async onConnect({ connector, address }) {
      setWalletAddress(address);
      const alreadyExists = wallets?.find(
        (thisWallet) => thisWallet.address === address
      );
      if (!alreadyExists) {
        createWallet(address, connector.id);
      }
    },
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setFireUser(user);
    });
  }, []);

  useEffect(() => {
    setWalletAddress(address);
  }, [address]);

  useEffect(() => {
    async function checkBackUser() {
      const res = await authenticateWithBackendUsingFirebase(fireUser?.uid);
      const resData = await res.json();

      if (!resData?.user) return;
      await refreshUser();
      setLoggedInState();
    }
    if (fireUser?.uid) {
      checkBackUser();
    }
  }, [fireUser, justCreatedUser]); // eslint-disable-line

  useEffect(() => {
    setLoggedIn(getPersistentLoggedInState());
  }, [loggedIn]);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const getIp = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
        const fullIp = (await getIp.text()).split('\n');
        const ipLine = fullIp.find((item) => item.includes('ip='));
        const parsedIp = ipLine.replace('ip=', '');
        setIp(parsedIp);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await fetch('/api/auth/user', {
          headers: {
            credentials: 'include',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
        } else {
          throw response;
        }
      } catch (error) {
        setUserError(error.status);
      }
    };

    if (loggedIn && !user) {
      fetchUser();
    }

    if (!ip) {
      fetchIp();
    }

    refreshIsLoggingInState();
  });

  useEffect(() => {
    const persistUserIp = async () => {
      await fetch('/api/auth/ip', {
        method: 'post',
        headers: { credentials: 'include', 'Content-Type': 'application/json' },
        body: JSON.stringify({ ip }),
      });
    };
    if (ip) {
      persistUserIp();
    }
  }, [ip]);

  function setLoggedInState() {
    localStorage.setItem('loggedIn', 'true');
    setLoggedIn(true);
    setLoggingIn(false);
  }

  function getPersistentLoggedInState(): boolean {
    try {
      return !!localStorage.getItem('loggedIn');
    } catch (ReferenceError) {
      return false;
    }
  }

  const authenticateWithBackendUsingFirebase = async (uuid: string) => {
    const response = await fetch('/api/auth/loginFirebase', {
      method: 'POST',
      headers: {
        credentials: 'include',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid }),
    });
    return response;
  };

  const signOutWithEthereum = () => {
    localStorage.removeItem('loggedIn');
    setLoggedIn(false);
    setUser(null);
    setFireUser(null);
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('wagmi.cache');
    localStorage.removeItem('wagmi.store');
    localStorage.removeItem('wagmi.wallet');
    localStorage.clear();
    setWalletAddress('0x');
    disconnect?.();
    fireSignOut(auth);
  };

  async function checkFirebaseUserExists(uuid: string) {
    try {
      const response = await client.query<GetUserIdByFirebaseQuery>({
        query: GetUserIdByFirebaseDocument,
        variables: {
          uuid,
        },
        fetchPolicy: 'no-cache',
      });

      return response?.data?.userIdByFirebase;
    } catch (error) {
      console.log('Firebase Google Auth failed', error.message);
    }
  }

  async function createUserFromFirebase(
    firstName: string,
    lastName: string,
    email: string,
    firebaseUUID: string
  ) {
    try {
      const response = await client.mutate<CreateUserMutation>({
        mutation: CreateUserDocument,
        variables: {
          name: firstName,
          lastName,
          email,
          firebaseUUID,
        },
        fetchPolicy: 'no-cache',
      });

      return response?.data?.createUser;
    } catch (error) {
      console.log('Firebase Google Auth failed', error.message);
    }
  }

  async function loginWithEmail(email: string, isConfirm: boolean) {
    if (!email) return false;
    try {
      const auth = getAuth();
      if (isConfirm) {
        if (isSignInWithEmailLink(auth, window.location.href)) {
          const result = await signInWithEmailLink(
            auth,
            email,
            window.location.href
          );
          if (!result?.user?.uid) return false;
          window.localStorage.removeItem('emailForSignIn');

          const names = result?.user?.displayName?.split(' ') || [];
          const firstName =
            names[0] ||
            result?.user?.displayName ||
            email?.split('@')?.[0] ||
            '';
          const lastName = names[names.length - 1] || '';
          await checkAnCreateUser(
            firstName,
            lastName,
            result?.user?.email,
            result?.user?.uid
          );

          setFireUser(result.user);
          return true;
        }
      } else {
        const actionCodeSettings = {
          url: `${location.origin}/sign-in-email?redirect=${encodeURIComponent(
            window.location.href
          )}`,
          handleCodeInApp: true,
        };

        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        localStorage.setItem('emailForSignIn', email);
        return true;
      }
    } catch (error) {
      console.log('Firebase Email Auth failed', error.message);
      return false;
    }
  }

  async function loginWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      if (!result?.user?.uid) return;
      const names = result?.user?.displayName?.split(' ') || [];
      const firstName =
        names[0] ||
        result?.user?.displayName ||
        result?.user?.email?.split('@')?.[0] ||
        '';
      const lastName = names[names.length - 1] || '';
      await checkAnCreateUser(
        firstName,
        lastName,
        result?.user?.email,
        result?.user?.uid
      );

      setFireUser(result.user);
    } catch (error) {
      console.log('Firebase Google Auth failed', error.message);
    }
  }

  async function loginWithFacebook() {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);

      if (!result?.user?.uid) return;
      const names = result?.user?.displayName?.split(' ') || [];
      const firstName =
        names[0] ||
        result?.user?.displayName ||
        result?.user?.email?.split('@')?.[0] ||
        '';
      const lastName = names[names.length - 1] || '';
      await checkAnCreateUser(
        firstName,
        lastName,
        result?.user?.email,
        result?.user?.uid
      );

      setFireUser(result.user);
    } catch (error) {
      console.log('Firebase Facebook Auth failed', error.message);
    }
  }

  async function loginWithApple() {
    try {
      const provider = new OAuthProvider('apple.com');
      const result = await signInWithPopup(auth, provider);

      if (!result?.user?.uid) return;
      const names = result?.user?.displayName?.split(' ') || [];
      const firstName =
        names[0] ||
        result?.user?.displayName ||
        result?.user?.email?.split('@')?.[0] ||
        '';
      const lastName = names[names.length - 1] || '';
      await checkAnCreateUser(
        firstName,
        lastName,
        result?.user?.email,
        result?.user?.uid
      );

      setFireUser(result.user);
    } catch (error) {
      console.log('Firebase Facebook Auth failed', error.message);
    }
  }

  async function checkAnCreateUser(
    firstName: string,
    lastName: string,
    email: string,
    uid: string
  ) {
    const exists = await checkFirebaseUserExists(uid);
    if (!exists) {
      const newUser = await createUserFromFirebase(
        firstName,
        lastName,
        email,
        uid
      );
      // State mostly to run the useEffect check for back-end user matching Firebase user again
      setJustCreatedUser(!!newUser?.id);
    }
    return exists;
  }

  const refreshUser = async () => {
    const response = await fetch('/api/auth/user', {
      headers: {
        credentials: 'include',
      },
    });
    const data = await response.json();
    setUser(data.user);
  };

  function refreshIsLoggingInState() {
    if (loggedIn && isLoggingIn) {
      setLoggingIn(false);
    }
  }

  const signOutWithEthereumCallback = useCallback(signOutWithEthereum, [
    disconnect,
  ]);

  const signOut = useCallback(() => {
    signOutWithEthereumCallback();
    router.push('/');
  }, [router, signOutWithEthereumCallback]);

  const fetchConcessions = useCallback(async () => {
    let concessions: CreatorConcession;
    if (!user?.id) return concessions;
    try {
      const response = await client.query<GetUserByIdQuery>({
        query: GetUserByIdDocument,
        variables: {
          id: user.id,
        },
        fetchPolicy: 'no-cache',
      });
      concessions = response?.data?.user?.creatorConcession;
    } catch (error) {
      console.log(error);
    }
    return concessions;
  }, [user]);

  useEffect(() => {
    if (!hasMounted || !fireUser) return;
    const backendIsLoggedOut = userError === 401;
    const isFirebaseLoggedOut = !fireUser?.uid;

    if ((backendIsLoggedOut || isFirebaseLoggedOut) && loggedIn) {
      signOut();
    }
  }, [userError, loggedIn, signOut, fireUser, hasMounted]);

  const values: AuthValues = {
    loginWithEmail,
    loginWithGoogle,
    loginWithFacebook,
    loginWithApple,
    loggedIn,
    user,
    fireUser,
    isCreator,
    walletAddress,
    wallets,
    createWallet,
    signOutWithEthereum,
    refreshUser,
    setLoggingIn,
    ip,
    fetchConcessions,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
