const general = {
  projectPublished: 'Project published!',
  publishingProject: 'Publishing project on chain...',
  confirmTransactionToRegisterProject:
    'Confirm the transaction in your wallet to register your project on chain.',
  raiseUpdated: 'Raise updated!',
  updatingRaise: 'Updating published raise on chain...',
  confirmTransactionToUpdateRaise:
    'Confirm the transaction in your wallet to update your published raise on chain.',
  raisePublished: 'Raise published!',
  publishingRaise: 'Publishing raise on chain...',
  confirmTransactionToPublishRaise:
    'Confirm the transaction in your wallet to publish your raise on chain.',
  withdrawRaiseFundsSure: 'Are you sure you want to withdraw raise funds?',
  withdrawingFunds: 'Withdrawing funds...',
  fundsWithdrawn: 'Funds withdrawn!',
  youOwn: 'You own',
  youDontOwnAnyTicket: "You don't own any tickets from this tier.",
  ticketRedeemed: 'Ticket redeemed!',
  toRedeemATicket:
    "To redeem your ticket for a refund, you'll need to approve Entertainmint to transfer your token.",
  cancelRaiseSure:
    'Are you sure you want to cancel this raise? Cancelling your raise will allow users to redeem their tickets in exchange for a refund.',
  cancellingRaise: 'Cancelling raise...',
  raiseCancelled: 'Raise cancelled!',
  closeRaiseSure:
    'Are you sure you want to close this raise? Once your raise is closed, you can withdraw funds, but patrons will no longer be able to mint new tickets.',
  closingRaise: 'Closing raise...',
  raiseClosed: 'Raise closed!',
  settleRaiseSure:
    "Are you sure you want to settle this raise? If your raise met its goal, you'll be able to withdraw funds to your wallet. If your raise did not meet its goal, users will be able to redeem their tickets for a refund.",
  settlingRaise: 'Settling raise...',
  raiseSettled: 'Raise settled!',
  purchasedTickets: 'Purchased Tickets',
  noTicketsBought: 'No tickets bought yet.',
  adminControlPanel: 'Admin Control Panel',
  addCompletedContent: 'Add new completed content for',
  backButton: '< Back',
  contentImageUploadCTALabel: 'Click or drop to upload an image',
  contentImageUploadCTADetailsCover:
    'recommended: 225 px wide x 300 px tall .PNG format',
  contentImageUploadCTADetailsThumbnail:
    'recommended: 245 px wide x 135 px tall .PNG format',
  virtualTheaterImageUploadCTADetailsLogo:
    'recommended: 300 px wide x 300 px tall .PNG format',
  virtualTheaterImageUploadCTADetailsBanner:
    'recommended: 1440 px wide x 644 px tall .PNG format',
  uploadingVideo: 'Uploading Content video...',
  contentSureDelete: 'Are you sure you want to delete this content?',
  episodeSureDelete: 'Are you sure you want to delete this episode?',
  voucherSureDelete: 'Are you sure you want to delete this voucher?',
  unableOnceDeleted:
    'You’ll be unable to retrieve this content once it has been deleted.',
  proceedTypeDelete: 'To proceed, type DELETE below.',
  watchSettings: 'Watch Settings',
  thisContentIs: 'This content is',
  freeContentCanBeViewed:
    'Free content can be viewed by anyone, whether they have an Entertainmint account or not.',
  gatedContentCanBeViewed:
    'Ticket-gated content can only be viewed by those who match the parameters you set below.',
  payPerViewCanWatch: 'Viewers can rent or purchase this content.',
  payPerViewMustHaveAtLeastOneRegisteredBankAccount:
    'To activate this feature, you must have at least one registered bank account.',
  payPerViewCanWatchLinkLabel: 'pricing recommendations here',
  ticketCanWatch: 'Viewers can watch if they hold tickets.',
  vourcherCanWatch:
    'Viewers can watch if they obtain one of the following codes. This is a great option for when you want a limited number of viewers to preview your content.',
  couponType: 'Coupon type',
  couponTypeFreePassUntilDate: 'Free pass until a date',
  couponTypeDiscountPercentage: 'Percentage discount',
  couponTypeDiscountValue: 'Value discount',
  rentItFor: 'Rent it for',
  rentEachFor: 'Rent each episode for',
  applyForAnEpisode: 'Apply for an episode',
  notForRent: 'Not for rent',
  rewatchFor: 'Rewatch Available for',
  sellItFor: 'Sell it for',
  sellSeasonFor: 'Sell the season for',
  sellEachFor: 'Sell each episode for',
  notForSale: 'Not for sale',
  noteEmintTakes: 'Note: Entertainmint takes 15% + fees',
  rentalAndSalesProceeds:
    'Rental and sale proceeds will be distributed to your',
  primaryWallet: 'Primary Wallet',
  orStripeAcc: 'or Stripe account',
  projectAndNeonAccepted:
    'project tickets and Entertainmint Neon passes will be accepted.',
  addTicketToken: 'Add an additional ticket or token',
  addVoucher: 'Add another promo code',
  promoCode: 'Promo Code',
  ticketHash: 'Ticket hash',
  rewatch72Hours: 'Rewatch available for 72 hours',
  discountPercentage: 'Discount Percentage',
  discountValue: 'Discount Value',
  freePassUntil: 'Free Pass Until',
  onePerUser: 'One per user',
  createdNewContent: 'Create new content successfully!',
  updatedContent: 'Updated content successfully!',
  deletedContent: 'Deleted content successfully!',
  createdNewEpisode: 'Create new episode successfully!',
  updatedEpisode: 'Updated episode successfully!',
  deletedEpisode: 'Deleted episode successfully!',
  createdNewVoucher: 'Create new voucher successfully!',
  updatedVoucher: 'Updated voucher successfully!',
  deletedVoucher: 'Deleted voucher successfully!',
  aFilm: 'a film',
  aSeasonWithEps: 'a season with episodes',
  aLiveStreamSession: 'a live-streaming session',
  setAsDefaultBankAccount: 'Set as default Bank Account',
  purchases: 'Purchases',
  noItems: 'No Items',
  loading: 'Loading...',
  user: 'Buyer',
  value: 'Value',
  method: 'Method',
  status: 'Status',
  coupon: 'Coupon',
  entitlement: 'Entitlement',
  project: 'Project',
  content: 'Content',
  episode: 'Episode',
  episodes: 'Episodes',
  CREATED: 'Created',
  PROCESSING: 'Processing',
  SUCCEEDED: 'Succeeded',
  PAYMENT_FAILED: 'Payment Failed',
  bankAccounts: 'Bank Accounts',
  accountHolderName: 'Holder Name',
  accountHolderType: 'Type',
  bankName: 'Bank Name',
  routingNumber: 'Routing Number',
  accountNumber: 'Account Number',
  last4: 'Last 4 numbers',
  newBankAccount: 'New Bank Account',
  selectAccountHolderType: 'Select the account type',
  accountHolderTypeIndividual: 'Individual',
  accountHolderTypeCompany: 'Company',
  forAllContent: 'For the entire content',
  customPriceObservation:
    'Custom prices must be whole numbers between $1 and $100',
  countryRestrictions: 'Countries Restrictions',
  restrictOrAllowCountries:
    'Restrict or allow specific countries to access your content.',
  payPerViewRestrictions: 'Pay Per View Restrictions',
  restrictKindOfPurchase:
    'Restrict what kind of purchase method can be used to access your content.',
  availableSale: 'Available For Sale',
  availableRent: 'Available For Rent',
  allowedCountries: 'Allowed Countries',
  disallowedCountries: 'Disallowed Countries',
  selectCountry: 'Select a Country',
  offerFinishedContent: 'Offer finished content for sale and rental',
  userOurTVOD: 'Use our TVOD features to sell or rent content to fans',
  raiseFanFunding: 'Raise fan funding for a project in-process',
  sellFanEngagementTickets:
    'Sell fan engagement tickets that allow for passive or participatory engagement into your project',
  iWantToHostEvent: 'I want to host an event',
  takeASeatVirtualTheater:
    'Take a seat in our Virtual Theater and to host an online event',
  wouldLikeReceiveInfoDevGrants:
    'I would like to receive info about development grants in exchange for equity.',
  wouldLikeReceiveInfoDistStreamers:
    'I would like to receive info about distribution to other streamers.',
  iAgreeWithEmint: "I agree with Entertainmint's",
  creatorApplication: 'Creator Application',
  haveAProject: 'Have a project?',
  applyGetStarted: 'Apply here to get started.',
  thereAreRequiredFields: 'There are still some required fields to be filled.',
  weLearnMoreYourProject: "We can't wait to learn more about your project",
  pleaseFillApplicationAcceptanceEmint:
    'Please fill out the following application to be considered for acceptance into the Entertainmint platform.',
  requiredCompleted: 'additional fields required',
  awardsAndFestivals: 'Awards & Festivals',
  thanksForApplying: 'Thanks for Applying!',
  youCanAlso: 'You can also',
  weReceivedInfoAcceptEmint:
    "We've received your information. We'll reach out shortly to let you know if you've been accepted to the Entertainmint Platform.",
  inTheMeantime: 'In the meantime, please',
  viewOurFaq: 'view our FAQs',
  forMoreInfoProjectSetupSupport:
    'for more information about project setup and support.',
  socialProfiles: 'Social Profiles',
  teamMembers: 'Team Members',
  bankingInfo: 'Banking Information',
  projectRoadmap: 'Project Roadmap',
  campaignFunding: 'Campaign Funding',
  basicsTabLabel: 'Basics',
  contentTabLabel: 'Content',
  bankTabLabel: 'Banking',
  fundingTabLabel: 'Funding',
  roadmapTabLabel: 'Roadmap',
  screeningsAndPreviews: 'Screenings & Previews',
  talksAndPanelDiscussions: 'Talks & Panel Discussions',
  youOwnAllAccessPass: 'You own an All-Access Pass!',
  yourPassUnlocksUnlimitedAccess:
    'During the festival, your pass unlocks unlimited access to festival content including panel talks, interviews, sneak peaks, film screenings, and more!',
  buyFullAccess: 'Buy all access',
  unlimitedViewingDuringEvent: 'unlimited viewing during the event',
  buyFor: 'Buy for',
  withPromoCode: 'with a promo code',
  orTryAnotherCode: 'or try another code',
  usePromoCode: 'Use Promo Code',
  proceedWithDiscount: 'Proceed With Discount',
  signinOrSignup: 'Sign in or Sign up',
  toPurchaseAccessVirtualTheaters: 'to purchase or access your virtual tickets',
  signIn: 'Sign in',
  successFetchBankAccounts: 'Successfully fetched your bank accounts',
  successDeleteBankAccount: 'Successfully deleted bank account',
  successCreateBankAccount: 'Successfully created bank account',
  successDefaultBankAccount: 'Successfully set bank account as default',
  successAcceptStripeTerms: "Successfully accepted Stripe's terms",
  toAddBankAcceptFirst: 'To add a bank account you must first accept',
  stripeToU: "Stripe's terms of use",
  setBankDefault: 'Set bank account as default',
  deleteBankAccount: 'Delete bank account',
  addNewBankAccount: 'Add new bank account',
  contentNotForSaleRent: 'This content is currently not up for sale or rent!',
  weValueYourPrivacy: 'We Value Your Privacy',
  weUseNecessaryCookies:
    'We use necessary cookies to make our site work. We do not use cookies for ad targeting and we do not sell personal data.',
  someEssentialCookiesDisabled:
    'Some essential cookies are disabled on your browser',
  youMustEnableThemSiteWorkProperly:
    'You must enable them for many site features to work properly.',
  liveStreaming: 'Live Streaming',
  shareProjectLink: 'Share Link',
  startLiveStreaming: 'Start Streaming Now',
  closeLiveStreamingRoom: 'Close Live Streaming Room',
  joinLiveStreaming: 'Join Live Streaming',
  streamIsOffline: 'Stream is offline',
  successConnectLiveRoom: 'Successfully connected to live room!',
  projectCurrentlyLiveStreaming:
    'This project is currently live streaming, sign in to watch!',
  homePageTitle: 'Breaking the wall between fans + creators',
  homePageSubTitle: 'Support + stream the best projects in TV + film',
  registerYourProject: 'Register Your Project',
  whetherYouCreatingNewOrLaunch:
    "Whether you're creating something new or are ready to launch, register your project here.",
  bePartOfShow: 'Be Part of the Show',
  whyWatchFavoriteShowBePartOfIt:
    'Why watch your favorite show when you can be a part of it? Check out our lineup.',
  streamContentNow: 'Stream Content Now',
  findSomethingForEveryoneAndStream:
    'Shows, movies, anime, livestreams, and more. Find something for everyone and stream now!',
  sureWantInvite: 'Are you sure you want to invite',
  sureWantRemove: 'Are you sure you want to remove',
  sureWantKick: 'Are you sure you want to kick',
  sureWantMute: 'Are you sure you want to mute',
  sureWantUnmute: 'Are you sure you want to unmute',
  sureWantEndEvent: 'Are you sure you want to end this event',
  sureWantLeaveEvent: 'Are you sure you want to leave this event',
  intoCall: 'into the call',
  fromCall: 'from the call',
  outRoom: 'out of the room',
  youSureWantToLeaveLiveRoom:
    'Are you sure you want to leave the live-streaming room?',
  thisStreamHasntStartedYet: "This live-streaming session hasn't started yet",
  thisStreamIsScheduledToStart: 'This stream is scheduled to start on',
  signInWatchLiveStream: 'Sign in to watch the livestream',
  thisEventIsLiveNow: 'This event is live now',
  buyTicketWatchLiveStream: 'Buy a ticket to watch the livestream',
  youAlreadyOwnTicketEvent: 'You already own a ticket for this event',
  connectedWallets: 'Connected Wallets',
  walletInformation: 'Wallet Information',
  videoDisplayDuringEvent:
    'Upload the content you want to pre-set for showing during the livestream',
  privateScreening: 'Private Screening',
  privateScreeningDescription:
    'This will limit the visibility of your live-streaming session and require you to add some form of ticket gating in the "Watch Settings" down below.',
  youOnStageTurnCamMic:
    'You are now on stage! Would you like to turn on your Camera and Microphone?',
  yourBrowserBlocksAudioEnableIt:
    'Your browser seems to be blocking your audio. Would you like to enable it?',
  chromiumBetterExperience:
    'For a better experience we recommend using Chromium-based browsers such as Chrome, Brave and Edge',
  projectCurrentLive: 'This project is currently live-streaming',
  theaterCurrentLive: 'This event is currently live-streaming',
  noLiveSessionCurrentlyActive: 'No live session currently active',
  checkEmailConfirmLogin: 'Check your email to confirm your login!',
  loginWithEmail: 'Login with Email',
  confirmingLogin: 'Confirming Login',
  pleaseProvideEmailConfirmation: 'Please provide your email for confirmation',
  ifFirstTimeCreateEmintAccount:
    'If this is your first time, you can create a new Entertainmint account',
  bySigningAgreeOut: 'By signing in you agree to our',
  successFetchingPledge: 'Pledge fetched successfully',
  successCreatingPledge: 'Pledge created successfully',
  successUpdatingPledge: 'Pledge updated successfully',
  successDeletingPledge: 'Pledge deleted successfully',
  sureDeletePledge: 'Are you sure you want to delete this pledge?',
  finishCreatePledge: 'Finish Creating The Pledge to Create Tiers',
  finishCreateTier: 'Finish Creating The Tier to Create Perks',
  sureDeleteTier: 'Are you sure you want to delete this Tier?',
  sureDeletePerk: 'Are you sure you want to delete this Perk?',
  successFetchingTier: 'Tier fetched successfully',
  successCreatingTier: 'Tier created successfully',
  successUpdatingTier: 'Tier updated successfully',
  successDeletingTier: 'Tier deleted successfully',
  successFetchingPerk: 'Perk fetched successfully',
  successCreatingPerk: 'Perk created successfully',
  successUpdatingPerk: 'Perk updated successfully',
  successDeletingPerk: 'Perk deleted successfully',
  successQueriedProjects: 'Successfully queried projects!',
  successUpdatingVirtualTheater: 'Virtual Theater Updated Successfully!',
  successDeletingVirtualTheater: 'Virtual Theater Deleted Successfully!',
  successQueryingConcessions: 'Concessions fetched successfully!',
  successCreatingConcession: 'Concession created successfully!',
  successUpdatingConcession: 'Concession updated successfully!',
  successDeletingConcession: 'Concession deleted successfully!',
  contentSubmittedEvent: 'Content Submitted for The Event',
  noContentThisEventYet: 'No content submitted for the event yet.',
  noContentThisProjectYet: 'No content added for this project yet.',
  sureWantApproveContentEvent:
    'Are you sure you want to approve this content to the event?',
  sureWantRejectContentEvent:
    'Are you sure you want to reject this content from the event?',
  sureWantSubmitContentEvent:
    'Are you sure you want to submit this content for the event?',
  sureWantRemoveContentEvent:
    'Are you sure you want to remove this content from the event?',
  myContentSubmitEvent: 'My Content to Submit for the Event',
  currentOnlySupportBankAccounts: 'We currently only support US bank accounts',
};

const errors = {
  genericError: 'Something went wrong!',
  genericErrorTryAgain: 'Something went wrong. Please try again.',
  errorSavingEmail: 'Error saving email, please try again.',
  emailAlreadyInUser: 'This email is already in use.',
  errorUpdatingProfile: 'Error updating profile, please try again.',
  mustBeLoggedToWatchVideo: 'You must be logged in to watch this video.',
  errorNewPerk: 'Error adding new Perk',
  errorNewPerkTryAgain: 'Error adding new Perk, please try again.',
  errorNewPerkMissingData:
    'Error adding new Perk: Perk created but missing data to update the interface, you might have to reload the page.',
  errorNewPerkMissingTitle:
    'Error adding new Perk, missing title or description.',
  errorWriteContract: 'Error when writing contract',
  errorWriteTransaction: 'Error when writing the transaction',
  errorReadTransaction: 'Error when reading transactions',
  errorUpdatingProject: 'Error when updating project',
  errorUploadingVideo: 'Error uploading video, please try again',
  errorCreateContent: 'Something went wrong while creating the content.',
  errorUpdateContent: 'Something went wrong while updating the content.',
  errorCreateEpisode: 'Something went wrong while creating the episode.',
  errorUpdateEpisode: 'Something went wrong while updating the episode.',
  errorUpdateEpisodeOrder:
    "Something went wrong while updating the episodes' orders.",
  errorCreateVoucher: 'Something went wrong while creating the voucher.',
  errorUpdateVoucher: 'Something went wrong while updating the voucher.',
  errorCreateBankAccount:
    'Something went wrong while creating the bank account.',
  errorDeleteBankAccount:
    'Something went wrong while deleting the bank account.',
  errorLoadBankAccount: 'Something went wrong while fetching bank accounts.',
  errorDefaultBankAccount:
    'Something went wrong while setting bank account as default.',
  errorAcceptStripe: "Something went wrong while accepting Stripe's terms.",
  thereWasErrorTryAgain: 'There was an error, please try again',
  defaultProjectBannerError: 'Error updating fields, please try again.',
  errorDuplicateProjectTitle:
    'There is already a project with this title. Please try another one!',
  errorPromoCodeAlreadyInUse: 'Promo code already in use in this content!',
  errorWhileConnectLiveRoom: 'Error while connecting to live room.',
  errorMissingTokens: 'Missing token!',
  errorPrivateScreeningCantBeFree:
    'Private Screenings can\'t be free! These require some sort of ticket gating. Check "Watch Settings"!',
  errorNotValidEmail: 'Not a valid email address',
  errorFetchingPledge: 'Error while fetching Pledge',
  errorCreatingPledge: 'Error while creating Pledge',
  errorUpdatingPledge: 'Error while updating Pledge',
  errorDeletingPledge: 'Error while deleting Pledge',
  errorFetchingTier: 'Error while fetching Tier',
  errorCreatingTier: 'Error while creating Tier',
  errorUpdatingTier: 'Error while updating Tier',
  errorDeletingTier: 'Error while deleting Tier',
  errorFetchingPerk: 'Error while fetching Perk',
  errorCreatingPerk: 'Error while creating Perk',
  errorUpdatingPerk: 'Error while updating Perk',
  errorDeletingPerk: 'Error while deleting Perk',
  errorQueryingProjects: 'Error while querying projects!',
  errorUpdatingVirtualTheater:
    'Something went wrong while updating the Virtual Theater!',
  errorDeletingVirtualTheater:
    'Something went wrong while deleting the Virtual Theater!',
  errorQueryingConcessions:
    'Something went wrong while querying the Concessions!',
  errorCreatingConcession: 'Something went wrong while creaing the Concession!',
  errorUpdatingConcession:
    'Something went wrong while updating the Concession!',
  errorDeletingConcession:
    'Something went wrong while deleting the Concession!',
  errorNotAllFieldsFilled: 'Not all fields were filled yet!',
};

const fields = {
  default: 'Default',
  destinationAddress: 'Destination address',
  approve: 'Approve',
  redeem: 'Redeem',
  transaction: 'Transaction',
  project: 'Project',
  tier: 'Tier',
  amount: 'Amount',
  editProject: 'Edit Project',
  projectLastUpdated: 'Project Last Updated',
  contactProjectOwner: 'Contact Project Owner',
  projectOwner: 'Project Owner',
  name: 'Name',
  email: 'Email',
  contentStatus: 'The status is',
  contentDraft: 'Draft',
  contentActive: 'Active',
  contentDraftMeans: 'Draft means the content is not currently viewable.',
  title: 'Title',
  presaleDate: 'Presale Date',
  ticketPresaleDate: 'Ticket Presale Date',
  releaseDate: 'Release Date',
  liveReleaseDate: 'Livestreaming Date',
  uploadFilm: 'Upload Film',
  addTrailer: 'Add a Trailer',
  addProjectBanner: 'Add a project banner image',
  addProjectBannerDescription:
    'This will be the primary image at the top of your project page. It will be the first image that users see.',
  addProjectPoster: 'Add a project poster image',
  addProjectExtraImages: ' Add an image extra(s)',
  addProjectExtraImagesDescription:
    ' An image extra could be anything from behind the scenes photos, cast photos, promotional photos, etc.',
  clickUploadBanner: 'Click or drop to upload a banner image',
  clickUploadPoster: 'Click or drop to upload a poster image',
  clickUploadExtraImage: 'Click or drop to upload an extra image',
  uploadBannerDetails:
    'JPEG, WEBP, PNG, SVG, or GIF minimum 1440 px wide 560 px tall',
  uploadPosterDetails:
    'JPEG, WEBP, PNG, SVG, or GIF minimum 225 px wide 300 px tall',
  trailersVideoExtras: 'Trailers & Video Extras',
  projectImages: 'Project Images',
  uploadThumb: 'Upload Thumbnail Photo',
  contentVideoUploadCTALabel: 'Click or drop to upload a video',
  projectTrailerUploadCTALabel: 'Click to add a trailer video',
  contentVideoUploadCTADetails: 'File size limit 30 Gb',
  description: 'Description',
  descriptionPlaceholder: 'Description goes here...',
  coverPhoto: 'Cover Photo',
  thumbnailPhoto: 'Thumbnail Photo',
  saveDraft: 'Save Draft',
  saveAndActive: 'Save & Set to Active',
  loading: 'Loading...',
  setAsDefault: 'Set as Default',
  delete: 'Delete',
  deleteFile: 'Delete File',
  deleteEpisode: 'Delete Episode',
  deleteForever: 'Delete Forever',
  cancel: 'Cancel',
  change: 'Change',
  remove: 'Remove',
  replace: 'Replace',
  filmFile: 'Film File',
  episode: 'Episode',
  addTitle: 'Add Title',
  addAnotherEpisode: 'Add another episode',
  addVideoFile: 'Add a video file',
  free: 'Free',
  ticketGated: 'Ticket-gated',
  on: 'On',
  off: 'Off',
  payPerView: 'Pay Per View',
  ticketGating: 'Ticket-gating',
  voucher: 'Promo Code',
  activate: 'Activate',
  unavailable: 'Unavailable',
  all: 'All',
  quantity: 'Quantity',
  expirationDate: 'Expiration date',
  close: 'Close',
  save: 'Save',
  other: 'Other',
  termsOfUser: 'Terms of Use',
  success: 'Success',
  hey: 'Hey',
  projectBasic: 'Project Basics',
  projectTitle: 'Project Title',
  projectDescription: 'Project Description',
  of: 'of',
  tagline: 'Tagline',
  websiteURL: 'Website URL',
  tags: 'Tags',
  addAnother: 'Add another',
  originalLanguage: 'Original Language',
  imageTypes: 'JPEG, WEBP, PNG, SVG, or GIF',
  status: 'Status',
  requestsToBeACreator: 'Requests to be a creator',
  requester: 'Requester',
  metadata: 'Metadata',
  reviewer: 'Reviewer',
  reviewed: 'Reviewed',
  reject: 'Reject',
  pending: 'Pending',
  approved: 'Approved',
  denied: 'Denied',
  accept: 'Accept',
  ok: 'Ok',
  acceptTheTerms: 'Accept the terms',
  addAward: 'Add an award',
  addFestival: 'Add a festival',
  addAnotherAward: 'Add another award',
  addAnotherFestival: 'Add another festival',
  addContent: 'Add Content',
  nextStep: 'Next Step',
  submitApplication: 'Submit Application',
  startStreaming: 'Start Streaming',
  viewOtherProjects: 'View Other Projects',
  fbUrlLabel: 'facebook.com/',
  instaUrlLabel: 'instagram.com/',
  tikUrlLabel: 'tiktok.com/',
  lkdnUrlLabel: 'linkedin.com/in/',
  twtUrlLabel: 'twitter.com',
  dscrdUrlLabel: 'discord.gg/',
  otherUrlLabel: 'Other social link',
  addAnotherTeam: 'Add another team member',
  milestoneTitle: 'Milestone Title',
  milestoneDescriptor: 'Milestone Descriptor',
  milestoneDate: 'Milestone Date',
  milestoneStep: 'Milestone Step',
  addAMilestone: 'Add a Milestone',
  newMilestone: 'New Milestone',
  addAnotherMilestone: 'Add another milestone',
  raiseAmounts: 'Raise Amounts',
  saleDates: 'Sale Dates',
  publicSaleStart: 'Public Sale Start',
  publicSaleEnd: 'Public Sale End',
  selectTheContent: 'Select the content you want to show',
  virtualTheater: 'Virtual Theater',
  virtualTheaters: 'Virtual Theaters',
  newVirtualTheater: 'New Virtual Theater',
  basicVirtualTheaterInfo:
    'Just the basics for now. First, what is the name of your virtual theater?',
  nextAddDescription: 'Next, add a short description.',
  nextAddPreSaleStartDate: 'Next, add a pre-sale start date.',
  nextAddLiveStartDate: 'Next, add a live start date.',
  nextAddLiveEndDate: 'Next, add a live end date.',
  allDoneirtualTheater:
    'All done! Click save below to create your project. Next, we&apos;ll take you to the virtual theater editor to configure all the details.',
  registerNewVirtualTheater: 'register a new virtual theater',
  theaterNotFound: 'Theater Not Found',
  comingSoon: 'Coming Soon',
  eventIsOver: 'Event Is Over',
  seeOur: 'See our',
  privacyPolicy: 'Privacy Policy',
  forMoreDetails: 'for more details',
  connected: 'Connected!',
  connecting: 'Connecting...',
  disconnected: 'Disconnected',
  reconnecting: 'Reconnecting',
  register: 'Register',
  stream: 'Stream',
  ourShows: 'Our Shows',
  you: 'You',
  host: 'Host',
  invite: 'Invite',
  removeFromCall: 'Remove From Call',
  kick: 'Kick',
  toggleCamera: 'Toggle Camera',
  toggleMic: 'Toggle Microphone',
  toggleScreenShare: 'Toggle Screen Sharing',
  typeAMsg: 'Type a message',
  live: 'Live',
  at: 'at',
  or: 'or',
  participants: 'Participants',
  onStage: 'On Stage',
  audience: 'Audience',
  handRaised: 'hand raised',
  inviteToStage: 'Invite to Stage',
  removeFromStage: 'Remove from Stage',
  mute: 'Mute',
  unmute: 'Unmute',
  chat: 'Chat',
  raisedHands: 'Raised Hands',
  endEvent: 'End Event',
  leave: 'Leave',
  liveNow: 'Live Now',
  video: 'Video',
  audio: 'Audio',
  muted: 'Muted',
  share: 'Share',
  shareScreen: 'Share Screen',
  fullscreen: 'Fullscreen',
  new: 'new',
  raiseHand: 'Raise Hand',
  buy: 'Buy',
  copied: 'Copied',
  disconnect: 'Disconnect',
  primary: 'Primary',
  newWallet: 'New Wallet',
  address: 'Address',
  provider: 'Provider',
  addLivestreamingRoom: 'Add Livestreaming Room',
  youCan: 'You can',
  file: 'File',
  newVideo: 'New Video',
  newEpisode: 'New Episode',
  newContent: 'New Content',
  createLiveStream: 'Create Live Stream',
  letsGo: "Let's Go",
  backToProjectPage: 'Back to project page',
  backToTheaterPage: 'Back to virtual theater page',
  signIn: 'Sign In',
  and: 'and',
  pledge: 'Pledge',
  addNewPledge: 'Add New Pledge',
  pledgeAmounts: 'Pledge Amounts',
  minimum: 'Minimum',
  target: 'Target',
  max: 'Max',
  saveNewPledge: 'Save New Pledge',
  addNewTier: 'Add New Tier',
  tierName: 'Tier Name',
  price: 'Price',
  deleteTier: 'Delete Tier',
  saveNewTier: 'Save New Tier',
  ticketTiers: 'Ticket Tiers',
  addNewPerk: 'Add New Perk',
  perkTitle: 'Perk Title',
  deletePerk: 'Delete Perk',
  saveNewPerk: 'Save New Perk',
  owner: 'Owner',
  projectsPage: "Project's Page",
  projectsCreatorPage: "Project's Creator Page",
  projectsStatus: "Project's Status",
  adminTools: 'Admin Tools',
  concessions: 'Concessions',
  projects: 'Projects',
  eventBanner: 'Event Banner',
  eventLogo: 'Event Logo',
  themeBGColor: 'Theme Background Color',
  themeTextColor: 'Theme Text Color',
  TalksPanelContent: 'Talks & Panel Content',
  submit: 'Submit',
  removeSubmission: 'Remove Submission',
  nineDigitsOnly: '9 digits (numbers only)',
  tenDigitsOnly: '10-12 digits (numbers only)',
};

// Prices are in the "smallest currency unit"
const values = {
  rentFilm: '$8 / feature film',
  rentFilmPrice: '800',
  sellFilm: '$12 / feature film',
  sellFilmPrice: '1200',
  rentEpisode: '$3 / episode',
  rentEpisodePrice: '300',
  sellEpisode: '$5 / episode',
  sellEpisodePrice: '500',
  sellSeason: '$24 / season',
  sellSeasonPrice: '2400',
};

const urls = {};

const en = { ...general, ...urls, ...errors, ...fields, ...values };

export default en;
