import countriesIsoCodes from './countries-iso.json';

export const DISPLAY = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536,
};

export const MAXS = {
  INT2: 32767,
  INT4: 2147483647,
  INT8: Number.MAX_SAFE_INTEGER,
};

export const PAY_PER_VIEW_OPTIONS = {
  episodePurchasePrice: {
    notForSale: '0',
    sellEpisode: '500',
    other: 'other',
  },
  episodeRentalPrice: {
    notForRent: '0',
    rentEpisode: '300',
    other: 'other',
  },
  seasonPurchasePrice: {
    notForSale: '0',
    sellSeason: '2400',
    other: 'other',
  },
  filmPurchasePrice: {
    notForSale: '0',
    sellFilm: '1200',
    other: 'other',
  },
  filmRentalPrice: {
    notForRent: '0',
    rentFilm: '800',
    other: 'other',
  },
  rentalTimeInHours: {
    unavailable: '0',
    rewatch72Hours: '72',
  },
};

export const COUNTRIES_ISO_CODES = countriesIsoCodes;

export const ACCOUNT_HOLDER_TYPE = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
};

export const SMALLEST_CURRENCY_UNITS_DIVISOR = {
  usd: 100,
};

export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
