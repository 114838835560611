export const HOME = 'https://entertainmint.zendesk.com/';
export const FAQ = 'https://entertainmint.zendesk.com/';
export const TEAM =
  'https://entertainmint.zendesk.com/hc/en-us/articles/10967260250391';
export const ADVISORS =
  'https://entertainmint.zendesk.com/hc/en-us/articles/10967422400151';
export const PRESS =
  'https://entertainmint.zendesk.com/hc/en-us/articles/10982798531095';
export const ABOUT =
  'https://entertainmint.zendesk.com/hc/en-us/articles/9965104873879';
export const CONTACT =
  'https://entertainmint.zendesk.com/hc/en-us/requests/new';
export const TERMS =
  'https://entertainmint.zendesk.com/hc/en-us/articles/17354368460695-Terms-of-Use';
export const PRIVACY =
  'https://entertainmint.zendesk.com/hc/en-us/articles/17354401143575-Privacy-Policy';
