import Head from 'next/head';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import React, { FC } from 'react';
import { ITour } from '@/components/common/Tour';
const Tour = dynamic(() => import('@/components/common/Tour'), { ssr: false });

interface WithChildrenProps {
  children: React.ReactNode;
  tour?: ITour;
  metadata?: {
    title?: string;
    description?: string;
    tags?: string;
    image?: string;
  };
}

const PageWithHead: FC<WithChildrenProps> = ({
  children,
  tour,
  metadata,
}: WithChildrenProps): JSX.Element => {
  const title = metadata?.title
    ? `Entertainmint - ${metadata?.title}`
    : 'Entertainmint';
  const description =
    metadata?.description ||
    'Breaking the wall between fans and creators. Support + stream the best projects in TV + film!';
  const tags =
    metadata?.tags ||
    'crowdfunding, crowd funding, content creator, web3, ethereum, watch movies, movies online, watch TV, TV online, TV shows online, watch TV shows, stream movies, stream tv, instant streaming, watch online, movies, watch TV online, no download, full length movies';
  const image =
    metadata?.image || 'https://entertainmint.com/emint_logo_rainbow.svg';
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://entertainmint.com" />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta name="keywords" content={tags} />
        <meta name="og:image" content={image} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div>
        {tour && <Tour {...tour} />}
        <Script
          defer
          data-domain="entertainmint.com"
          src="https://plausible.io/js/plausible.js"
        ></Script>
        {children}
      </div>
    </>
  );
};

export default PageWithHead;
