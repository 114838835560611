import type { NextPage } from 'next';
import { useHasMounted } from 'src/hooks/useHasMounted';
import NavHeader from '@/components/common/NavHeader';
import PageWithHead from '@/components/PageWithHead';
import { SVG } from '@/components/common/SVG';
import Link from 'next/link';

const ProjectNotFound: NextPage = () => {
  const hasMounted = useHasMounted();
  const InfoText = () => {
    return (
      <div className="pb-10 pl-10 text-3xl text-white uppercase text-end">
        404 | Project Not Found
      </div>
    );
  };

  const GoHomeButton = () => {
    return (
      <div className="flex justify-end items-center p-4 h-8 text-sm font-medium leading-4 text-center text-white rounded-3xl lg:h-8 bg-emint-purple">
        <Link href="/">Go Home!</Link>
      </div>
    );
  };

  return (
    <PageWithHead>
      {hasMounted && (
        <div className="w-screen h-screen bg-emint-gray-600">
          <div className="relative z-10">
            <NavHeader />
          </div>
          <div className="flex justify-center">
            <div className="p-8 w-6/12 rounded-xl border-2 lg:m-40 lg:bg-opacity-25 border-emint-purple min-h-3/6 bg-emint-black">
              <div className="flex">
                <SVG
                  className="flex-shrink-0 pr-5 m-2 w-2/12 md:m-0 md:w-2/12 md:h-full lg:m-0 lg:w-2/12"
                  type="emintLogoRainbow"
                />
                <div className="flex-col justify-end w-full">
                  <InfoText />
                </div>
              </div>
              <div className="flex justify-center pt-5">
                <GoHomeButton />
              </div>
            </div>
          </div>
        </div>
      )}
    </PageWithHead>
  );
};
export default ProjectNotFound;
