import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { browserEnv } from './browser-env-vars';

const firebaseConfig = {
  apiKey: browserEnv('NEXT_PUBLIC_FIRE_API_KEY'),
  authDomain: browserEnv('NEXT_PUBLIC_FIRE_AUTH_DOMAIN'),
  projectId: browserEnv('NEXT_PUBLIC_FIRE_PROJECT_ID'),
  storageBucket: browserEnv('NEXT_PUBLIC_FIRE_STORAGE_BUCKET'),
  messagingSenderId: browserEnv('NEXT_PUBLIC_FIRE_MSG_SENDER_ID'),
  appId: browserEnv('NEXT_PUBLIC_FIRE_APP_ID'),
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
