import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { translateByLocale } from './utils';

export default function useTranslation() {
  // Avoid destructing the router since the tests would require a lot of changes in router provider
  const router = useRouter();
  const locale = router?.locale || 'en';
  const defaultLocale = router?.defaultLocale || 'en';

  const t = useCallback(
    (key: string): string => {
      return translateByLocale(locale, defaultLocale, key);
    },
    [locale, defaultLocale]
  );
  return { t };
}
