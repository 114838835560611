import React, { FC } from 'react';

import FundingSVG from '@/assets/funding_icon.svg';
import CommunitySVG from '@/assets/community_icon.svg';
import NetworkSVG from '@/assets/network_icon.svg';
import BrandSVG from '@/assets/brand_icon.svg';
import CheckMarkSVG from '@/assets/check_mark.svg';
import CircularPlaySVG from '@/assets/circular_play.svg';
import CrashPunksMobile from '@/assets/crashpunks_mobile.svg';
import CrashPunksTablet from '@/assets/crashpunks_tablet.svg';
import CrowdfundSVG from '@/assets/crowdfund.svg';
import DiscordSVG from '@/assets/discord.svg';
import RedditSVG from '@/assets/reddit.svg';
import TikTokSVG from '@/assets/tiktok.svg';
import EntertainmintSVG from '@/assets/entertainmint.svg';
import EmintLogo from '@/assets/entertainmint_logo.svg';
import EmintCoin from '@/assets/emint_coin.svg';
import EmintCoinClipped from '@/assets/emint_coin_clipped.svg';
import FacebookSVG from '@/assets/facebook.svg';
import FilmReelSVG from '@/assets/film_reel.svg';
import HowItWorksGraphic from '@/assets/how_it_works_graphic.svg';
import InstagramSVG from '@/assets/insta.svg';
import OpenSeaSVG from '@/assets/opensea.svg';
import TwitterSVG from '@/assets/twitter.svg';
import EthSVG from '@/assets/eth.svg';
import EmintLogoRainbow from '@/assets/emint_logo_rainbow.svg';
import EmintRedLogo from '@/assets/redLogo.svg';
import EmintTextSVG from '@/assets/emint_text.svg';
import Linkedin from '@/assets/linkedin.svg';
import Globe from '@/assets/globe.svg';
import PartOfShow from '@/assets/part_of_show.svg';
import StreamContent from '@/assets/stream_content.svg';

export type SVGType =
  | 'funding'
  | 'community'
  | 'network'
  | 'eth'
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'discord'
  | 'reddit'
  | 'tiktok'
  | 'opensea'
  | 'filmReel'
  | 'crowdfund'
  | 'play'
  | 'entertainmint'
  | 'emintTextSVG'
  | 'emintLogo'
  | 'emintLogoRainbow'
  | 'emintRedLogo'
  | 'brand'
  | 'emintCoin'
  | 'emintCoinClipped'
  | 'checkMark'
  | 'linkedin'
  | 'globe'
  | 'crashpunksMobile'
  | 'crashpunksTablet'
  | 'howItWorks'
  | 'partOfShow'
  | 'streamContent';

interface SVG extends React.HTMLAttributes<HTMLElement> {
  type: SVGType;
  height?: string | number;
  width?: string | number;
  color?: string;
}

const icons = {
  funding: FundingSVG,
  community: CommunitySVG,
  network: NetworkSVG,
  eth: EthSVG,
  facebook: FacebookSVG,
  instagram: InstagramSVG,
  twitter: TwitterSVG,
  discord: DiscordSVG,
  reddit: RedditSVG,
  tiktok: TikTokSVG,
  opensea: OpenSeaSVG,
  filmReel: FilmReelSVG,
  crowdfund: CrowdfundSVG,
  play: CircularPlaySVG,
  entertainmint: EntertainmintSVG,
  emintTextSVG: EmintTextSVG,
  brand: BrandSVG,
  emintCoin: EmintCoin,
  emintCoinClipped: EmintCoinClipped,
  emintLogo: EmintLogo,
  emintLogoRainbow: EmintLogoRainbow,
  emintRedLogo: EmintRedLogo,
  checkMark: CheckMarkSVG,
  crashpunksMobile: CrashPunksMobile,
  crashpunksTablet: CrashPunksTablet,
  howItWorks: HowItWorksGraphic,
  linkedin: Linkedin,
  globe: Globe,
  partOfShow: PartOfShow,
  streamContent: StreamContent,
};

export const SVG: FC<SVG> = (props) => {
  const color = props.color || 'text-emint-gray-100';
  props = { ...props };
  const CustomSVG = icons[props.type];
  return <CustomSVG className={color} {...props} />;
};
