import { Component, PropsWithChildren } from 'react';
import ProjectNotFound from 'src/pages/project-not-found';
import GenericErrorPage from 'src/pages/generic-error';

interface ErrorBoundaryState {
  hasError: boolean;
  isNotFound: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false, isNotFound: false };
  }

  static getDerivedStateFromError(error) {
    if (error.message.includes('Cannot return null')) {
      return { isNotFound: true };
    }
    return { hasError: true };
  }

  render() {
    if (this.state.isNotFound) {
      return <ProjectNotFound />;
    }
    if (this.state.hasError) {
      return <GenericErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
