import {
  GetUserWalletsQuery,
  GetUserWalletsDocument,
  CreateWalletMutation,
  CreateWalletDocument,
} from '@emint/graphql';
import { useCallback, useEffect, useState } from 'react';
import client from 'src/apollo/client';
import useTranslation from 'src/i18n/useTranslation';
import { User } from 'src/context/authContext';
import { Wallet } from 'src/types/users';

export default function useMyWallets(user: User) {
  const { t } = useTranslation();

  const [wallets, setWallets] = useState<Wallet[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (!wallets?.length) {
      loadWallets();
    }
  }, [user]); // eslint-disable-line

  const loadWallets = useCallback(async () => {
    if (!user?.id) return;
    setErrorMessage('');
    setSuccessMsg('');
    setLoading(true);
    try {
      const result = await client.query<GetUserWalletsQuery>({
        query: GetUserWalletsDocument,
        variables: {
          id: user.id,
        },
        fetchPolicy: 'no-cache',
      });
      if (result.data.getUserWallets) {
        setWallets(result.data.getUserWallets);
      }
      setSuccessMsg(t('successFetchBankAccounts'));
      setErrorMessage('');
    } catch (error) {
      setSuccessMsg('');
      setErrorMessage(t('errorLoadBankAccount'));
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [user]); // eslint-disable-line

  async function createWallet(address: string, provider: string) {
    if (!user?.id || !address || !provider) return;

    setErrorMessage('');
    setSuccessMsg('');
    setLoading(true);
    try {
      await client.mutate<CreateWalletMutation>({
        mutation: CreateWalletDocument,
        variables: {
          id: user.id,
          address,
          provider,
        },
      });
      loadWallets();
      setSuccessMsg(t('successCreateBankAccount'));
      setErrorMessage('');
    } catch (error) {
      setSuccessMsg('');
      setErrorMessage(t('errorCreateBankAccount'));
    } finally {
      setLoading(false);
    }
  }

  return {
    wallets,
    loadWallets,
    createWallet,
    loading,
    errorMessage,
    successMsg,
  };
}
