import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
import { browserEnv } from 'src/config/browser-env-vars';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `${browserEnv('NEXT_PUBLIC_GRAPHQL_HOST')}`,
    credentials: 'include',
    fetch,
  }),
});

export default client;
