import en from './en';

const labels = {
  en,
};

export function translateByLocale(
  locale: string,
  defaultLocale: string,
  key: string
): string {
  return (
    labels?.[locale]?.[key] ||
    labels?.[defaultLocale]?.[key] ||
    'MISSING TRANSLATION'
  );
}
