import { FormEvent, useState } from 'react';
import { useAuthContext } from 'src/context/authContext';
import useTranslation from 'src/i18n/useTranslation';
import { emailRegex } from 'src/utils/constants';

export const ConnectWalletButton = ({
  canProceed,
}: {
  canProceed: boolean;
}) => {
  const { t } = useTranslation();
  const [isLoginEmail, setIsLoginEmail] = useState(false);
  const {
    loginWithGoogle,
    // loginWithFacebook,
    loginWithApple,
  } = useAuthContext();

  return (
    <>
      {isLoginEmail ? (
        <EmailPasswordLogin close={() => setIsLoginEmail(false)} />
      ) : (
        <div className="flex flex-col gap-4 justify-center items-center p-4 bg-white">
          <div className="flex gap-4 justify-center items-center w-full">
            <button
              className="flex justify-center items-center p-2 font-bold rounded-lg border-2 border-black border-solid disabled:text-gray-500 disabled:border-gray-500 hover:bg-emint-purple disabled:hover:bg-transparent"
              type="button"
              onClick={() => setIsLoginEmail(true)}
              disabled={!canProceed}
            >
              {t('loginWithEmail')}
            </button>
          </div>
          <div className="flex gap-4 justify-center items-center w-full">
            {/* <button
              className="flex justify-center items-center p-2 rounded-lg border-2 border-black border-solid hover:bg-emint-purple"
              type="button"
              onClick={loginWithFacebook}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 50 50"
              >
                <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
              </svg>
            </button> */}
            <button
              className="flex justify-center items-center p-2 rounded-lg border-2 border-black border-solid disabled:text-gray-500 disabled:border-gray-500 hover:bg-emint-purple disabled:hover:bg-transparent"
              type="button"
              onClick={loginWithGoogle}
              disabled={!canProceed}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 50 50"
                fill="currentColor"
              >
                <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z"></path>
              </svg>
            </button>
            <button
              className="flex justify-center items-center p-2 rounded-lg border-2 border-black border-solid disabled:text-gray-500 disabled:border-gray-500 hover:bg-emint-purple disabled:hover:bg-transparent"
              type="button"
              onClick={loginWithApple}
              disabled={!canProceed}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                width="100"
                height="100"
                version="1.1"
                id="Capa_1"
                viewBox="0 0 22.773 22.773"
              >
                <g>
                  <g>
                    <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573    c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z" />
                    <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334    c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0    c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019    c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464    c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648    c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z" />
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </g>
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

function EmailPasswordLogin({ close }: { close: () => void }) {
  const { t } = useTranslation();
  const { loginWithEmail } = useAuthContext();
  const [email, setEmail] = useState('');

  const [errorMsg, setErrorMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setErrorMsg('');

    const isEmailValid = emailRegex.test(email);
    if (!isEmailValid) {
      setErrorMsg(t('errorNotValidEmail'));
      return;
    }

    const success = await loginWithEmail(email, false);

    if (success) {
      setIsSuccess(true);
    } else {
      setErrorMsg(t('genericErrorTryAgain'));
    }
  }

  return (
    <div className="flex flex-col gap-4 justify-center items-center p-4 bg-white">
      {isSuccess ? (
        <div className="flex flex-col gap-4 justify-center items-center p-12 w-full text-2xl text-center">
          {t('checkEmailConfirmLogin')}
        </div>
      ) : (
        <>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 justify-center items-center w-full"
          >
            <label className="flex flex-col gap-2 justify-start items-center font-bold uppercase">
              {t('email')}
              <input
                type="email"
                className="rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <button
              className="py-2 px-6 text-white rounded-3xl hover:bg-opacity-50 bg-emint-purple"
              type="submit"
            >
              {t('signIn')}
            </button>
            <p className="italic text-center text-red-500">{errorMsg}</p>
          </form>
          <button type="button" onClick={close} className="underline">
            {t('cancel')}
          </button>
        </>
      )}
    </div>
  );
}
