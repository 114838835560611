import '@rainbow-me/rainbowkit/styles.css';

import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';
import { useAuthContext } from 'src/context/authContext';
import { useHasMounted } from 'src/hooks/useHasMounted';

import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { ConnectWalletButton } from './ConnectWalletButton';

import { TERMS, PRIVACY } from 'src/config/zendesk';
import useTranslation from 'src/i18n/useTranslation';

const SignedInDropDown = () => {
  const { user, signOutWithEthereum, walletAddress } = useAuthContext();
  const router = useRouter();

  const displayUserInformation = () => {
    try {
      return user.name ?? displayWalletAddress(walletAddress);
    } catch (e) {
      return 'Signed In';
    }
  };

  function displayWalletAddress(walletAddress: string) {
    return (
      walletAddress.slice(0, 5) +
      '...' +
      walletAddress.slice(walletAddress.length - 4)
    );
  }

  const signOut = () => {
    signOutWithEthereum();
    router.push('/');
  };

  return (
    <div>
      <Menu as="div" className="inline-block relative text-left">
        <div>
          <Menu.Button className="flex justify-center items-center p-4 h-8 text-sm font-medium leading-4 text-center text-white rounded-3xl lg:h-8 bg-emint-purple">
            {displayUserInformation()}
            <ChevronDownIcon
              className="ml-2 -mr-1 w-5 h-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 bg-white rounded-md divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none">
            <div className="py-1 px-1">
              <Menu.Item>
                <span>
                  <Link href="/profile">
                    <a className="flex items-center py-2 px-2 w-full text-sm text-gray-900 rounded-md hover:text-white group hover:bg-emint-purple">
                      Profile
                    </a>
                  </Link>
                </span>
              </Menu.Item>
            </div>
            {user?.roles.includes('creator') && (
              <div className="py-1 px-1">
                <Menu.Item>
                  {({ active }) => (
                    <span>
                      <Link href="/creator/dashboard">
                        <a className="flex items-center py-2 px-2 w-full text-sm text-gray-900 rounded-md hover:text-white group hover:bg-emint-purple">
                          Creator Dashboard
                        </a>
                      </Link>
                    </span>
                  )}
                </Menu.Item>
              </div>
            )}
            <div className="py-1 px-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-emint-purple text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={signOut}
                  >
                    Sign Out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const SignedInMobile = () => {
  const { signOutWithEthereum, walletAddress, user } = useAuthContext();

  const displayUserInformation = () => {
    try {
      return user.name ?? displayWalletAddress(walletAddress);
    } catch (e) {
      return 'Signed In';
    }
  };

  function displayWalletAddress(walletAddress: string) {
    return (
      walletAddress.slice(0, 5) +
      '...' +
      walletAddress.slice(walletAddress.length - 4)
    );
  }

  return (
    <div className="flex flex-col justify-center items-center mr-2 w-11/12 text-white rounded-xl bg-emint-gray-550 h-[110px]">
      <span className="mb-4">Signed in as {displayUserInformation()}</span>

      <button
        className="flex justify-center items-center p-4 h-8 text-sm font-medium leading-5 text-center text-white rounded-3xl lg:h-8 bg-emint-purple"
        type="submit"
        onClick={signOutWithEthereum}
      >
        Disconnect Wallet
      </button>
    </div>
  );
};

interface SignInButtonProps {
  text: string;
  openModal: () => void;
}

const SignInButton = ({ text, openModal }: SignInButtonProps) => {
  return (
    <button
      id="btnSignIn"
      className="flex justify-center items-center p-4 h-8 text-sm font-medium leading-4 text-center text-white rounded-3xl lg:h-8 bg-emint-purple"
      type="submit"
      onClick={openModal}
    >
      {text}
    </button>
  );
};

interface SignInModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const SignInModal = ({ isOpen, closeModal }: SignInModalProps) => {
  const { t } = useTranslation();
  const [canProceed, setCanProceed] = useState(false);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0">
          <div className="flex justify-center items-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="overflow-hidden w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                ></Dialog.Title>
                <div className="py-8 px-4">
                  <div className="flex flex-col gap-3 justify-center items-center p-2 bg-white">
                    <h1 className="text-xl font-semibold tracking-wide text-center">
                      {t('signIn')}
                    </h1>
                    <span className="text-sm font-medium text-center">
                      {t('ifFirstTimeCreateEmintAccount')}.
                    </span>
                    <label className="flex gap-2 justify-center items-center text-xs">
                      <input
                        type="checkbox"
                        checked={canProceed}
                        onChange={(e) => setCanProceed(e.target.checked)}
                      />
                      <p className="w-full text-center">
                        {t('bySigningAgreeOut')}{' '}
                        <Link href={TERMS}>
                          <a
                            className="underline hover:no-underline"
                            target="_blank"
                          >
                            Terms of Use
                          </a>
                        </Link>{' '}
                        {t('and')}{' '}
                        <Link href={PRIVACY}>
                          <a
                            className="underline hover:no-underline"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </Link>
                      </p>
                    </label>
                  </div>
                  <div className="relative">
                    <ConnectWalletButton canProceed={canProceed} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface SignInProps {
  text?: string;
}

export const SignInMobile = ({ text }: SignInProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { loggedIn } = useAuthContext();
  const hasMounted = useHasMounted();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  if (!hasMounted) {
    return null;
  }

  if (loggedIn) {
    return <SignedInMobile />;
  }

  return (
    <>
      <SignInButton text={text || 'Sign In'} openModal={openModal} />
      <SignInModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};

export const SignIn = ({ text }: SignInProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { loggedIn } = useAuthContext();
  const hasMounted = useHasMounted();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  if (!hasMounted) {
    return null;
  }

  if (loggedIn) {
    return <SignedInDropDown />;
  }

  return (
    <>
      <SignInButton text={text || 'Sign In'} openModal={openModal} />
      <SignInModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};
