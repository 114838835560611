import '../../styles/globals.css';

import React from 'react';
import { browserEnv, browserInProduction } from 'src/config/browser-env-vars';
import { AuthProvider } from 'src/context/authContext';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, goerli } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import ErrorBoundary from '@/components/common/ErrorBoundary';
import { ApolloProvider } from '@apollo/client';
import {
  connectorsForWallets,
  darkTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { Analytics } from '@vercel/analytics/react';

import graphqlClient from '../apollo/client';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import CookiesBanner from '@/components/CookiesBanner';

function getChains() {
  if (browserInProduction()) {
    return mainnet;
  }
  return goerli;
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [getChains()],
  [
    alchemyProvider({ apiKey: browserEnv('NEXT_PUBLIC_ALCHEMY_ID') }),
    publicProvider(),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Sign in with a wallet',
    wallets: [
      metaMaskWallet({
        chains,
        projectId: browserEnv('NEXT_PUBLIC_RAINBOW_PROJECT_ID'),
      }),
      rainbowWallet({
        chains,
        projectId: browserEnv('NEXT_PUBLIC_RAINBOW_PROJECT_ID'),
      }),
      walletConnectWallet({
        chains,
        projectId: browserEnv('NEXT_PUBLIC_RAINBOW_PROJECT_ID'),
      }),
      coinbaseWallet({ appName: 'Entertainmint', chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors,
});

interface ProjectPageProps {
  projectServer?: {
    title?: string;
    description?: string;
    tags?: string[];
    poster?: {
      readURL?: string;
    };
  };
}

type CustomAppProps = AppProps & {
  pageProps: ProjectPageProps;
};

function MyApp({ Component, pageProps }: CustomAppProps) {
  const project = pageProps?.projectServer;

  const title = project?.title
    ? `Entertainmint - ${project?.title}`
    : 'Entertainmint';
  const description =
    project?.description ||
    'Breaking the wall between fans and creators. Support + stream the best projects in TV + film!';
  const tags = project?.tags
    ? project?.tags?.join?.(' , ')
    : 'crowdfunding, crowd funding, content creator, web3, ethereum, watch movies, movies online, watch TV, TV online, TV shows online, watch TV shows, stream movies, stream tv, instant streaming, watch online, movies, watch TV online, no download, full length movies';
  const image =
    project?.poster?.readURL ||
    'https://entertainmint.com/emint_logo_rainbow.svg';
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://entertainmint.com" />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta name="keywords" content={tags} />
        <meta name="og:image" content={image} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          theme={darkTheme({ ...darkTheme.accentColors.purple })}
        >
          <AuthProvider>
            <ApolloProvider client={graphqlClient}>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </ApolloProvider>
          </AuthProvider>
        </RainbowKitProvider>
      </WagmiConfig>
      <CookiesBanner />
      <Analytics />
    </>
  );
}

export default MyApp;
