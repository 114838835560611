import '@rainbow-me/rainbowkit/styles.css';

import Link from 'next/link';
import React from 'react';
import { useAuthContext } from 'src/context/authContext';

export const NavBar = () => {
  const { user } = useAuthContext();

  return (
    <div className="flex flex-row pt-2 pl-12 space-x-10 text-white sm:text-sm md:text-lg">
      <Link className="flex flex-row" href="/projects">
        <a>Projects</a>
      </Link>
      <Link className="flex flex-row" href="/stream">
        <a>Stream</a>
      </Link>
      <Link className="flex flex-row" href="/theaters/HACC">
        <a>HACC Festival</a>
      </Link>
      <Link
        className="flex flex-row"
        href={
          user?.roles.includes('creator')
            ? '/creator/dashboard'
            : '/creator/signup'
        }
      >
        <a>Creators</a>
      </Link>
      {user?.roles.includes('admin') && (
        <Link className="flex flex-row" href="/purchases">
          <a>Purchases</a>
        </Link>
      )}
    </div>
  );
};
