import { EnvVarNotFound } from './errors';

// Browser visible: suffix NEXT_PUBLIC_
export const publicEnvVars = {
  NEXT_PUBLIC_MAILCHIMP_URL: process.env.NEXT_PUBLIC_MAILCHIMP_URL,
  NEXT_PUBLIC_GRAPHQL_HOST: process.env.NEXT_PUBLIC_GRAPHQL_HOST,
  NEXT_PUBLIC_ALCHEMY_ID: process.env.NEXT_PUBLIC_ALCHEMY_ID,
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_MAGIC_PUBLIC_KEY: process.env.NEXT_PUBLIC_MAGIC_PUBLIC_KEY,
  NEXT_PUBLIC_RAINBOW_PROJECT_ID: process.env.NEXT_PUBLIC_RAINBOW_PROJECT_ID,
  NEXT_PUBLIC_LIVEKIT_WS_URL: process.env.NEXT_PUBLIC_LIVEKIT_WS_URL,
  NEXT_PUBLIC_FIRE_API_KEY: process.env.NEXT_PUBLIC_FIRE_API_KEY,
  NEXT_PUBLIC_FIRE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIRE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIRE_PROJECT_ID: process.env.NEXT_PUBLIC_FIRE_PROJECT_ID,
  NEXT_PUBLIC_FIRE_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIRE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIRE_MSG_SENDER_ID: process.env.NEXT_PUBLIC_FIRE_MSG_SENDER_ID,
  NEXT_PUBLIC_FIRE_APP_ID: process.env.NEXT_PUBLIC_FIRE_APP_ID,
};

export const browserEnv = (varName: string) => {
  const value = publicEnvVars[varName];
  if (value) {
    return value;
  } else {
    throw new EnvVarNotFound(`Browser env var ${varName} not found.`);
  }
};

export const browserInProduction = () =>
  browserEnv('NEXT_PUBLIC_APP_ENV') == 'production';
