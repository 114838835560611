import { useState } from 'react';
import Link from 'next/link';
import { ADVISORS, TEAM } from 'src/config/zendesk';
import { useAuthContext } from 'src/context/authContext';

import { SVG } from '@/components/common/SVG';

import { NavBar } from './NavBar';
import { SignIn, SignInMobile } from './SignIn';

function MobileNav({ open, setOpen }) {
  const { user } = useAuthContext();

  return (
    <div
      className={`absolute z-10 top-0 left-0 h-screen w-screen bg-emint-gray-600 md:hidden transform ${
        open ? '-translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out filter drop-shadow-md `}
    >
      <div className="flex justify-center items-center h-20 filter drop-shadow-md bg-emint-gray-600">
        {' '}
        {/*logo container*/}
        <Link href="/">
          <a className="text-xl font-semibold">
            <SVG className="w-[126px] md:w-[170px]" type="entertainmint" />
          </a>
        </Link>
      </div>
      <div className="flex flex-col ml-4 text-white">
        {user?.roles.includes('creator') && (
          <Link href="/creator/dashboard">
            <a
              className="my-4 text-xl font-medium"
              onClick={() =>
                setTimeout(() => {
                  setOpen(!open);
                }, 100)
              }
            >
              Creators
            </a>
          </Link>
        )}
        <Link href="/projects">
          <a
            className="my-4 text-xl font-medium"
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          >
            Projects
          </a>
        </Link>
        <Link href="/stream">
          <a
            className="my-4 text-xl font-medium"
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          >
            Stream
          </a>
        </Link>
        <Link href="/theaters/HACC">
          <a
            className="my-4 text-xl font-medium"
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          >
            HACC Festival
          </a>
        </Link>
        <Link href={TEAM}>
          <a
            className="my-4 text-xl font-normal"
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          >
            Team
          </a>
        </Link>
        <Link href={ADVISORS}>
          <a
            className="my-4 text-xl font-normal"
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          >
            Advisors
          </a>
        </Link>
        {user?.roles?.includes('admin') && (
          <Link href="/purchases">
            <a
              className="my-4 text-xl font-medium"
              onClick={() =>
                setTimeout(() => {
                  setOpen(!open);
                }, 100)
              }
            >
              Purchases
            </a>
          </Link>
        )}
      </div>
      <div className="flex justify-center p-2">
        <SignInMobile />
      </div>
    </div>
  );
}

export default function NavHeader() {
  const [open, setOpen] = useState(false);
  return (
    <nav className="mx-auto w-11/12">
      <MobileNav open={open} setOpen={setOpen} />
      <div className="flex relative py-5">
        <Link href="/">
          <a className="p-2">
            <SVG className="w-[126px] md:w-[170px]" type="entertainmint" />
          </a>
        </Link>

        <div className="hidden md:flex">
          <NavBar />
        </div>

        <div
          className="flex absolute right-5 z-50 flex-col justify-between w-8 h-8 md:hidden"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {/* hamburger button */}
          <span
            className={`h-1 w-full bg-white rounded-lg transform transition duration-300 ease-in-out ${
              open ? 'rotate-45 translate-y-3.5' : ''
            }`}
          />
          <span
            className={`h-1 w-full bg-white rounded-lg transition-all duration-300 ease-in-out ${
              open ? 'w-0' : 'w-full'
            }`}
          />
          <span
            className={`h-1 w-full bg-white rounded-lg transform transition duration-300 ease-in-out ${
              open ? '-rotate-45 -translate-y-3.5' : ''
            }`}
          />
        </div>
        <div className="hidden absolute right-0 p-2 text-lg text-white bg-transparent bg-opacity-30 md:flex">
          <SignIn />
        </div>
      </div>
    </nav>
  );
}
