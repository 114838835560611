import React, { useEffect, useState } from 'react';
import useTranslation from 'src/i18n/useTranslation';
import { PRIVACY } from 'src/config/zendesk';

export default function CookiesBanner() {
  const { t } = useTranslation();
  const [accepted, setAccepted] = useState(true);
  const [canUseCookies, setCanUseCookies] = useState(true);

  function acceptCookies() {
    setAccepted(true);
    localStorage.setItem('accepted-cookies', 'true');
  }

  function checkCookieUsage() {
    const allCookies = document.cookie?.split(';') || [];
    const hasIPCookie = allCookies.some((thisCookie) => {
      return thisCookie?.trim?.()?.startsWith?.('ip=');
    });
    setCanUseCookies(hasIPCookie);
  }

  useEffect(() => {
    const acceptedItem = localStorage.getItem('accepted-cookies');
    setAccepted(!!acceptedItem);
  }, []);

  useEffect(() => {
    if (accepted) {
      checkCookieUsage();
    }
  }, [accepted]);

  return (
    <>
      {!accepted ? (
        <div className="flex fixed bottom-0 left-0 z-10 justify-center p-4 w-full">
          <div className="flex flex-col gap-2 p-4 max-w-sm text-white rounded-lg border-2 border-white border-solid bg-emint-gray-600">
            <h1 className="text-lg font-bold">{t('weValueYourPrivacy')}</h1>
            <p>{t('weUseNecessaryCookies')}</p>
            <p>
              {t('seeOur') + ' '}
              <a
                href={PRIVACY}
                className="underline cursor-pointer text-emint-purple"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('privacyPolicy')}
              </a>
              {' ' + t('forMoreDetails')}.
            </p>
            <div className="flex justify-center">
              <button
                onClick={acceptCookies}
                className="py-2 px-4 rounded-3xl hover:opacity-50 bg-emint-purple"
              >
                {t('accept')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {accepted && !canUseCookies ? (
        <div className="flex fixed bottom-0 left-0 z-10 justify-center p-4 w-full">
          <div className="flex flex-col gap-2 p-4 max-w-sm text-white rounded-lg border-2 border-white border-solid bg-emint-gray-600">
            <h1 className="text-lg font-bold">
              {t('someEssentialCookiesDisabled')}
            </h1>
            <p>{t('youMustEnableThemSiteWorkProperly')}</p>
            <div className="flex justify-center">
              <button
                onClick={() => setCanUseCookies(true)}
                className="py-2 px-4 rounded-3xl hover:opacity-50 bg-emint-purple"
              >
                {t('ok')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
